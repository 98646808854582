import tools from '../../../../libs/tools';
import { required } from '@vuelidate/validators';

const model = {
    id: "",
    slug: "",
    name: "",
    translations: {}
};
const translation = {
    author_id: "",
    locale: "",
    description: ""
};

export default {
    ...tools.models.apiCommons(model, translation, "author_id"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            name: {
                required
            }
        }
    },
    translationValidation: {
        model: {
            description: {
                required
            }
        }
    }
};
