<template>
    <div v-if='model && !model.is_null'>

        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid'>
                <div class='field col-12'>

                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='v$.model.description.$model'
                        :key='"cont_auth_desc_"+id+"_"+lang'
                        :class="{'p-invalid': v$.model.description.$errors.length}"
                        contentType='html'/>
                    <label>Descrizione</label>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Navigations from '../../../../mixins/navigations';
import Notifications from '../../../../mixins/notifications';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/Authors');
const commonStore = createNamespacedHelpers('Common');

import useVuelidate from '@vuelidate/core'

import tools from '../../../../libs/tools';
import model from './model';

export default {
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.translationValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        ...commonStore.mapGetters(['languages']),
        lastUpdateDate() {
            return tools.time.lastUpdate(this.model['last_update']);
        },
    },
    watch: {
        row(n){
            this.initModel(n);
        },
        lang(){
            this.initModel(this.row);
        },
        dirty(n){
            if (n) this.formIsDirty();
            else this.formIsClean();
        }
    },
    mounted() {
        this.initModel(this.row);
    },
    methods: {
        ...mapMutations(['formIsDirty', 'formIsClean']),
        ...mapActions(['saveTranslation']),
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }


            this.saveTranslation({
                data: this.model,
                parent_id: model.translationParentId(this.model)
            }).then(() => {
                this.formIsClean();
                this.ShowSuccess('Ottimo', 'Traduzione salvata con successo');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initModel(mod){
            this.dirty = false;
            const base = model.newTranslation(this.id, this.lang);
            if (mod && mod.translations && mod.translations[this.lang]){
                this.model = tools.models.fillTranslation(mod.translations[this.lang], base);
                return;
            }

            this.model = base;
        }
    },
    props: {
        lang: {
            type: String,
            default: 'it'
        },
        id: {
            type: String
        }
    },
    mixins: [
        Navigations, Notifications
    ]
}
</script>
